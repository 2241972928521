import React from 'react';
import { Box } from '@heycater/design-system';
import Image from 'next/image';
import styled from 'styled-components';

import newsletterImg from '@images/homepage/newsletter-illustration.png';
import SignUpForm from 'hub/components/SignUpForm';

const HomepageNewsletter = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
      >
        <Box
          mr={5}
          mb={{ xs: 4, md: 0 }}
          justifySelf="center"
          display={{ xs: 'flex' }}
          maxHeight={{ xs: '200px', md: 'none' }}
        >
          <NewsletterImage alt="" src={newsletterImg} objectFit="contain" />
        </Box>
        <Box maxWidth={{ xs: '600px', md: '500px' }} mx="auto">
          <SignUpForm />
        </Box>
      </Box>
    </>
  );
};

const NewsletterImage = styled(Image)`
  aspect-ratio: 16/9;
`;

export default HomepageNewsletter;
